import {
  Heading,
  LinkBox,
  LinkOverlay,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import * as React from 'react';
import { Hit } from 'react-instantsearch-core';

import { IOrganizationSearch } from '@/client/legacy-shared-modules/search/search-types';
import { EAttributeOwnerKind } from '@/graphql-types/globalTypes';
import { useHighlightedAttributes } from '@/hooks/useHighlightedAttributes';
import { GlobeIcon, MapPinIcon, PhoneIcon } from '@/imports/ui/chakra/feather';
import { Highlight } from '@/imports/ui/components/algolia/Highlight';
import { useOrganizationDirectory } from '@/imports/ui/hooks/useOrganizationDirectory';

import { ProfileImage } from './components/ProfileImage';

const ATTRIBUTES_WITH_CUSTOM_DISPLAY_RULES = [
  'company-name',
  'company-website',
  'company-primaryPhone',
  'company-addressLocality',
  'company-addressAdministrativeArea',
  'company-addressRegionCode',
  'aboutUs',
];

export const CompanyHit: React.FC<{
  organization: Hit<IOrganizationSearch>;
  facetFilters?: string[];
  isSponsorDirectory: boolean;
}> = ({ organization, facetFilters, isSponsorDirectory }) => {
  const { searchableAttributesField, viewableAttributesField } =
    useOrganizationDirectory({
      filter: isSponsorDirectory ? { isSponsor: true } : { isMember: true },
    });

  const attributesToHighlight = useHighlightedAttributes(
    EAttributeOwnerKind.ORGANIZATION,
    organization._highlightResult,
    searchableAttributesField,
    facetFilters
  ).filter(
    (attribute) => !ATTRIBUTES_WITH_CUSTOM_DISPLAY_RULES.includes(attribute._id)
  );

  const typedViewableAttributesField = viewableAttributesField as
    | 'adminMemberDirectoryViewable'
    | 'membersWithMemberStatusMemberDirectoryViewable'
    | 'loggedInMemberDirectoryViewable';
  const companyAttributes = organization[typedViewableAttributesField];

  if (
    !companyAttributes ||
    !searchableAttributesField ||
    !viewableAttributesField
  ) {
    return null;
  }

  return (
    <LinkBox key={organization.objectID}>
      <Stack direction="row" spacing="4">
        <ProfileImage
          src={organization.profilePictureUrl || undefined}
          alt={organization.name}
        />
        <Stack flex="1" spacing="2">
          <LinkOverlay
            as={NextLink}
            href={`/companies/${organization.objectID}`}
          >
            <Heading size="sm" flex="1">
              {organization.name}
            </Heading>
          </LinkOverlay>
          <Text color="gray.600" mt="4" mb="2">
            <Highlight attribute="aboutUs" hit={organization} />
          </Text>
          <Wrap color="gray.600" mt="2">
            {companyAttributes['company-website'] && (
              <WrapItem>
                <Text wordBreak="break-word">
                  <GlobeIcon mr="1" boxSize={3} color="gray.700" />
                  <Highlight
                    attribute="company-website"
                    hit={organization}
                    searchableAttributesField={searchableAttributesField}
                    viewableAttributesField={viewableAttributesField}
                  />
                </Text>
              </WrapItem>
            )}
            {companyAttributes['company-primaryPhone'] && (
              <WrapItem>
                <Text>
                  <PhoneIcon mr="1" boxSize={3} color="gray.700" />
                  <Highlight
                    attribute="company-primaryPhone"
                    hit={organization}
                    searchableAttributesField={searchableAttributesField}
                    viewableAttributesField={viewableAttributesField}
                  />
                </Text>
              </WrapItem>
            )}
            {companyAttributes['company-addressLocality'] ||
            companyAttributes['company-addressAdministrativeArea'] ||
            companyAttributes['company-addressRegionCode'] ? (
              <WrapItem>
                <Text>
                  <MapPinIcon mr="1" boxSize={3} color="gray.700" />
                  {companyAttributes['company-addressLocality'] && (
                    <Highlight
                      attribute={'company-addressLocality'}
                      hit={organization}
                      searchableAttributesField={searchableAttributesField}
                      viewableAttributesField={viewableAttributesField}
                    />
                  )}
                  {(companyAttributes['company-addressAdministrativeArea'] ||
                    companyAttributes['company-addressRegionCode']) && (
                    <>
                      {companyAttributes['company-addressLocality'] && ', '}
                      {companyAttributes[
                        'company-addressAdministrativeArea'
                      ] && (
                        <Highlight
                          attribute={'company-addressAdministrativeArea'}
                          hit={organization}
                          searchableAttributesField={searchableAttributesField}
                          viewableAttributesField={viewableAttributesField}
                        />
                      )}
                      {companyAttributes['company-addressAdministrativeArea'] &&
                        companyAttributes['company-addressRegionCode'] &&
                        ', '}
                      {companyAttributes['company-addressRegionCode'] && (
                        <Highlight
                          attribute={'company-addressRegionCode'}
                          hit={organization}
                          searchableAttributesField={searchableAttributesField}
                          viewableAttributesField={viewableAttributesField}
                        />
                      )}
                    </>
                  )}
                </Text>
              </WrapItem>
            ) : null}
            {attributesToHighlight.map((attribute) => {
              return (
                <WrapItem key={attribute._id}>
                  <Text color="gray.600">
                    {attribute.name}:{' '}
                    <Highlight
                      attribute={attribute._id}
                      hit={organization}
                      searchableAttributesField={searchableAttributesField}
                      viewableAttributesField={viewableAttributesField}
                    />
                  </Text>
                </WrapItem>
              );
            })}
          </Wrap>
        </Stack>
      </Stack>
    </LinkBox>
  );
};
