import { Box, BoxProps } from '@chakra-ui/react';
import * as React from 'react';

import { Blueprint, BlueprintPortal } from '@/layouts/components/Blueprint';

export const ButterflyMain: React.FC<BoxProps> = (props) => {
  return <Box as="main" {...props} />;
};

export const ButterflyPortal = BlueprintPortal;

export const Butterfly = Blueprint;
