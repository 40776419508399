import { Divider, Stack, StackDivider } from '@chakra-ui/react';
import * as React from 'react';
import { HitsProvided } from 'react-instantsearch-core';
import { connectHits } from 'react-instantsearch-dom';

import { IOrganizationSearch } from '@/client/legacy-shared-modules/search/search-types';
import { CompanyHit } from '@/components/CompanyHit';

type HitsProps = HitsProvided<IOrganizationSearch> & {
  facetFilters: string[];
};

const CustomHits: React.FC<HitsProps> = ({ hits, facetFilters }) => {
  return (
    <>
      {!!hits.length && <Divider my="6" />}
      <Stack spacing="6" divider={<StackDivider />}>
        {hits.map((sponsor) => (
          <CompanyHit
            key={sponsor.objectID}
            organization={sponsor}
            facetFilters={facetFilters}
            isSponsorDirectory={true}
          />
        ))}
      </Stack>
    </>
  );
};

export const Hits = connectHits<HitsProps, IOrganizationSearch>(CustomHits);
