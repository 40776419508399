import { Box, Image } from '@chakra-ui/react';
import * as React from 'react';

import { ImageFallback } from '@/imports/ui/chakra/components/ImageFallback';

export const ProfileImage: React.FC<{ src?: string; alt: string }> = (
  props
) => {
  return props.src ? (
    <Box h={36} w={36} p="2" boxShadow="base" borderRadius="lg" bg="white">
      <Image
        src={props.src}
        h="full"
        w="full"
        objectFit="contain"
        alt={props.alt}
      />
    </Box>
  ) : (
    <ImageFallback h={36} w={36} borderRadius="lg" />
  );
};
