import { gql, useQuery } from '@apollo/client';
import algoliasearch, { SearchClient } from 'algoliasearch';
import * as React from 'react';

import { GetOrganizationsSearchKeysInput } from '@/graphql-types/globalTypes';

import {
  UseOrganizationDirectory_GetOrganizationSearchKeys,
  UseOrganizationDirectory_GetOrganizationSearchKeysVariables,
} from './graphql-types/UseOrganizationDirectory_GetOrganizationSearchKeys';

const QUERY = gql`
  query UseOrganizationDirectory_GetOrganizationSearchKeys(
    $input: GetOrganizationsSearchKeysInput!
  ) {
    getOrganizationsSearchKeys(input: $input) {
      applicationId
      searchApiKey
      indexData {
        indexName
        searchableAttributesField
        viewableAttributesField
      }
    }
  }
`;

export const useOrganizationDirectory = (
  input: GetOrganizationsSearchKeysInput
): {
  loading: boolean;
  indexName?: string;
  searchClient: SearchClient | null;
  searchableAttributesField?: string;
  viewableAttributesField?: string;
} => {
  const query = useQuery<
    UseOrganizationDirectory_GetOrganizationSearchKeys,
    UseOrganizationDirectory_GetOrganizationSearchKeysVariables
  >(QUERY, { variables: { input } });

  const searchClient = React.useMemo(() => {
    return query.data?.getOrganizationsSearchKeys.applicationId &&
      query.data?.getOrganizationsSearchKeys.searchApiKey
      ? algoliasearch(
          query.data.getOrganizationsSearchKeys.applicationId,
          query.data.getOrganizationsSearchKeys.searchApiKey
        )
      : null;
  }, [
    query.data?.getOrganizationsSearchKeys.applicationId,
    query.data?.getOrganizationsSearchKeys.searchApiKey,
  ]);

  return {
    loading: query.loading,
    searchClient,
    indexName: query.data?.getOrganizationsSearchKeys.indexData.indexName,
    searchableAttributesField:
      query.data?.getOrganizationsSearchKeys.indexData
        .searchableAttributesField,
    viewableAttributesField:
      query.data?.getOrganizationsSearchKeys.indexData.viewableAttributesField,
  };
};
