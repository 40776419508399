import { gql, useQuery } from '@apollo/client';
import {
  Box,
  Divider,
  Flex,
  SimpleGrid,
  Spacer,
  Stack,
} from '@chakra-ui/react';
import { SearchClient } from 'algoliasearch';
import * as React from 'react';
import { Configure } from 'react-instantsearch-dom';

import { MoreFilters } from '@/components/MoreFilters';
import { SelectedFilters } from '@/components/SelectedFilters';
import { EAttributeOwnerKind } from '@/graphql-types/globalTypes';
import { useCurrent } from '@/hooks/useCurrent';
import { useFilterableAttributes } from '@/hooks/useFilterableAttributes';
import { Card } from '@/imports/ui/chakra/components/Card';
import { Loading } from '@/imports/ui/chakra/components/Loading';
import { UnexpectedError } from '@/imports/ui/chakra/components/UnexpectedError';
import { Pagination } from '@/imports/ui/components/algolia/Pagination';
import { SearchBox } from '@/imports/ui/components/algolia/SearchBox';
import { SearchTips } from '@/imports/ui/components/algolia/SearchTips';
import { StateResults } from '@/imports/ui/components/algolia/StateResults/StateResults';
import { InstantSearchWithErrorLogging } from '@/imports/ui/components/search/InstantSearchWithErrorLogging';
import { useGetAbsoluteUrl } from '@/imports/ui/hooks/useGetAbsoluteUrl';
import { useOrganizationDirectory } from '@/imports/ui/hooks/useOrganizationDirectory';
import { Butterfly, ButterflyMain } from '@/layouts/Butterfly';

import { SponsorDirectory_GetSponsorTierNames } from './graphql-types/SponsorDirectory_GetSponsorTierNames';

import { Hits } from './components/Hits';

const GET_SPONSOR_TIER_NAMES = gql`
  query SponsorDirectory_GetSponsorTierNames {
    getSponsorTierNames {
      result
    }
  }
`;

export const _SponsorDirectory: React.FC<{
  indexName: string;
  searchClient: SearchClient | null;
  searchableAttributesField?: string;
  sponsorTierNames: string[];
}> = ({
  indexName,
  searchClient,
  searchableAttributesField,
  sponsorTierNames,
}) => {
  const {
    selectedFilters,
    unselectedFilters,
    selectFilter,
    unselectFilter,
    isLoadingFilters,
    facetFilters,
  } = useFilterableAttributes({
    indexName,
    searchClient,
    searchableAttributesField,
    topLevelFilterableAttributes: [
      {
        _id: 'sponsorTier.name',
        name: 'Sponsorship Level',
        options: sponsorTierNames.map((name) => {
          return { id: name, label: name };
        }),
      },
    ],
  });

  const current = useCurrent();

  if (isLoadingFilters) {
    return <Loading />;
  }

  return (
    <InstantSearchWithErrorLogging
      indexName={indexName}
      searchClient={searchClient}
    >
      <Configure hitsPerPage={8} facetFilters={facetFilters} />

      <Stack spacing="4">
        <Card>
          <Stack spacing="0" divider={<Divider />}>
            <Flex py="4" pl="4">
              <SearchBox
                placeholder={`Search ${current.tenant?.organizationEntityName}`}
              />
              <SearchTips ownerKind={EAttributeOwnerKind.ORGANIZATION} />
            </Flex>
            {(selectedFilters.length > 0 || unselectedFilters.length > 0) && (
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing="4" p="4">
                <SelectedFilters
                  selectedFilters={selectedFilters}
                  unselectFilter={unselectFilter}
                  selectFilter={selectFilter}
                />

                <Flex direction="column">
                  <Spacer />
                  <MoreFilters
                    unselectedFilters={unselectedFilters}
                    selectFilter={selectFilter}
                  />
                </Flex>
              </SimpleGrid>
            )}
          </Stack>
        </Card>

        <Card px="4" py="6" pos="relative">
          <StateResults />
          <Hits facetFilters={facetFilters} />
        </Card>

        <Pagination />
      </Stack>
    </InstantSearchWithErrorLogging>
  );
};

export const SponsorDirectory: React.FC = () => {
  const { loading, indexName, searchClient, searchableAttributesField } =
    useOrganizationDirectory({
      filter: { isSponsor: true },
    });

  const query = useQuery<SponsorDirectory_GetSponsorTierNames>(
    GET_SPONSOR_TIER_NAMES
  );

  return (
    <Butterfly>
      <ButterflyMain>
        <Box maxW="container.sm" mx="auto" py="4">
          {loading ? (
            <Loading />
          ) : !indexName ? (
            <UnexpectedError />
          ) : (
            <_SponsorDirectory
              indexName={indexName}
              searchClient={searchClient}
              searchableAttributesField={searchableAttributesField}
              sponsorTierNames={query.data?.getSponsorTierNames.result ?? []}
            />
          )}
        </Box>
      </ButterflyMain>
    </Butterfly>
  );
};
